import BeforeAfter from "@/components/help-center/custom-tags/BeforeAfter.vue";

export const beforeAfter = {
  render: BeforeAfter,
  attributes: {
    beforeImageSource: {
      type: String,
    },
    afterImageSource: {
      type: String,
    },
  },
};
